import { z } from 'zod';
// Copied from https://github.com/colinhacks/zod#json-type
const literalSchema = z.union([z.string(), z.number(), z.boolean(), z.null()]);
export const jsonGuard = z.lazy(() => z.union([literalSchema, z.array(jsonGuard), z.record(jsonGuard)]));
export const jsonObjectGuard = z.record(jsonGuard);
export const socialUserInfoGuard = z.object({
    id: z.string(),
    email: z.string().optional(),
    phone: z.string().optional(),
    name: z.string().optional(),
    avatar: z.string().optional(),
    rawData: jsonGuard.optional(),
});
export const connectorSessionGuard = z
    .object({
    nonce: z.string(),
    redirectUri: z.string(),
    connectorId: z.string(),
    connectorFactoryId: z.string(),
    jti: z.string(),
    state: z.string(),
})
    .partial()
    // Accept arbitrary unspecified keys so developers who can not publish @logto/connector-kit can more flexibly utilize connector session.
    .catchall(z.unknown());
