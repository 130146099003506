import { jsonObjectGuard } from '@logto/connector-kit';
import { z } from 'zod';
import { MfaFactor } from './sign-in-experience.js';
export const userProfileGuard = (z.object({
    familyName: z.string(),
    givenName: z.string(),
    middleName: z.string(),
    nickname: z.string(),
    preferredUsername: z.string(),
    profile: z.string(),
    website: z.string(),
    gender: z.string(),
    birthdate: z.string(),
    zoneinfo: z.string(),
    locale: z.string(),
    address: z
        .object({
        formatted: z.string(),
        streetAddress: z.string(),
        locality: z.string(),
        region: z.string(),
        postalCode: z.string(),
        country: z.string(),
    })
        .partial(),
})).partial();
export const userProfileKeys = Object.freeze(userProfileGuard.keyof().options);
export const roleNamesGuard = z.string().array();
export const identityGuard = z.object({
    userId: z.string(),
    details: jsonObjectGuard.optional(), // Connector's userinfo details, schemaless
});
export const identitiesGuard = z.record(identityGuard);
export const baseMfaVerification = {
    id: z.string(),
    createdAt: z.string(),
    lastUsedAt: z.string().optional(),
};
export const mfaVerificationTotp = z.object({
    type: z.literal(MfaFactor.TOTP),
    ...baseMfaVerification,
    key: z.string(),
});
export const webAuthnTransportGuard = z.enum([
    'usb',
    'nfc',
    'ble',
    'internal',
    'cable',
    'hybrid',
    'smart-card',
]);
export const mfaVerificationWebAuthn = z.object({
    type: z.literal(MfaFactor.WebAuthn),
    ...baseMfaVerification,
    credentialId: z.string(),
    publicKey: z.string(),
    transports: webAuthnTransportGuard.array().optional(),
    counter: z.number(),
    agent: z.string(),
});
export const mfaVerificationBackupCode = z.object({
    type: z.literal(MfaFactor.BackupCode),
    ...baseMfaVerification,
    codes: z.object({ code: z.string(), usedAt: z.string().optional() }).array(),
});
export const mfaVerificationGuard = z.discriminatedUnion('type', [
    mfaVerificationTotp,
    mfaVerificationWebAuthn,
    mfaVerificationBackupCode,
]);
export const mfaVerificationsGuard = mfaVerificationGuard.array();
